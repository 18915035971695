import React from "react";
import { Link } from "gatsby";
import { menuItems } from "./navbar/menuItems";
import UserForm from '@/components/form/EOI-Form';
import { StaticImage } from "gatsby-plugin-image";
import { Facebook, Linkedin, Instagram, Twitter } from 'lucide-react';
import { KeyDatesBar } from "./KeyDates";
import CountdownTimer from '@/components/Countdown';

const iconMap = {
  facebook: <Facebook strokeWidth={1} size={30} />,
  linkedin: <Linkedin strokeWidth={1} size={30} />,
  instagram: <Instagram strokeWidth={1} size={30} />,
  twitter: <Twitter strokeWidth={1} size={30} />,
};

const FooterLinkSection = ({ label, links }) => (
  <div className="space-y-4">
    <h4 className="text-lg font-bold">{label}</h4>
    <ul className="space-y-2 text-slate-700">
      {links.map((link, index) => (
        <li key={index}>
          <Link to={link.to}>{link.label}</Link>
        </li>
      ))}
    </ul>
  </div>
);

export default function Footer() {

  const eventName = '5WCWS 2025';
  const eventDate = '2024-07-03';

  return (
    <>
      <section className="bg-dbrown">
        <div className="container mx-auto px-4 md:px-6 py-12">
            <h2 className="text-center text-white ms-5">Key dates</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <KeyDatesBar />
          </div>
        </div>
      </section>
      <footer className="w-full bg-ivory text-slate-900 py-12 md:py-16 flex flex-col gap-10">
        <section>
          <div className="container mx-auto relative">
            <StaticImage
              src="../images/women-gathering-elements.png"
              alt="Women Gathering Elements"
              width={200}
              loading="lazy"
              placeholder="none"
              formats={["auto", "webp", "avif"]}
              className="!absolute top-[-80px] lg:top-[-130px] right-5 lg:right-0 w-[100px] lg:w-[200px]"
            />
          </div>
          <div className="w-full">
            <div className="flex items-center justify-center w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center w-full container p-4 md:p-8 rounded-lg gap-10 md:gap-0">
                <div className="w-full md:pr-8 order-2 md:order-1">
                  <h2>Express your interest</h2>
                  <UserForm eventName={eventName} eventDate={eventDate} isAlt={true} />
                </div>
                <div className="w-full md:pl-8 order-1 md:order-2 border-gray-500 md:border-l">
                  <div className="flex flex-col justify-center items-center p-5 bg-dteal rounded-xl mb-10">
                    <h4 className="text-white text-2xl">Conference starts in</h4>
                    <CountdownTimer targetDate="2025-09-15" />
                  </div>
                  <div>
                    <h2>Acknowledgement of country</h2>
                    <p>We acknowledge the Traditional Owners of the lands and waters throughout Australia and pay respect to the Elders past, present and emerging. We recognise the importance of connection to culture, land, kinship and community to the health and wellbeing of Aboriginal & Torres Strait Islander families. We acknowledge the cultural practices and traditions still carried out today and being passed down to future generations.</p>
                  </div>
                  <div>
                    <h2>Honouring First Nations Australians</h2>
                    <p>Learn more about First Nations Australians, their history and the inspiration behind our logo on <Link to="/about-our-logo" className="text-dteal hover:underline">this page</Link>.</p>
                    <div className="flex gap-3 items-center">
                      <StaticImage
                        src="../images/indigenous-flag.webp"
                        alt="Indigenous Flag"
                        width={150}
                        loading="lazy"
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                      />
                      <StaticImage
                        src="../images/torres-stait.webp"
                        alt="Torres Stait Flag"
                        width={150}
                        loading="lazy"
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container mx-auto px-4 md:px-6 py-6 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-8">
          {menuItems.footer.map((section, index) => (
            <FooterLinkSection key={index} label={section.label} links={section.links} />
          ))}
        </section>
        <section className="container mx-auto px-4 md:px-6 mt-8 text-center text-slate-900">
          <div className="flex flex-col lg:flex-row justify-between gap-3">
            <div className="order-2 lg:order-1">© 2024 <a href="https://icmsaust.com.au/" target="_blank" rel="noopener noreferrer" className="text-dteal">ICMSA</a>. All rights reserved. | <a href="https://icmsaust.com.au/privacy-policy/" target="_blank" rel="noopener noreferrer" className="text-dteal">Privacy Policy</a></div>

            <div className="flex flex-row gap-3 order-1 lg:order-2 justify-center items-end">
              <span className="font-bold text-gray-700">#5WCWS</span>
              {menuItems.social.map((social, index) => (
                <a
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="text-slate-900 hover:text-dteal flex items-center"
                  aria-label={social.screenReader}
                >
                {iconMap[social.name.toLowerCase()]}
                <span className="sr-only">{social.screenReader}</span>
              </a>
            ))}
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}
